<template>
  <div class="mobile-view">
    <div class="img img1"></div>

    <div class="img img2"></div>

    <div class="img img3"></div>

    <div class="img img4">
      <van-image
        class="mission1"
        :src="
          require(`@/assets/images/activity10/${
            mission1 ? 'done.png' : 'todo.png'
          }`)
        "
      ></van-image>

      <van-image
        class="mission2"
        :src="
          require(`@/assets/images/activity10/${
            mission2 ? 'done.png' : 'todo.png'
          }`)
        "
      ></van-image>

      <div class="button">
        <van-button
          v-if="receiveState == 1"
          class="button1"
          :style="buttonStyle"
          color="linear-gradient(to right, #EC722E, #E93323)"
          disabled
        >
          已领取奖励
        </van-button>

        <van-button
          v-else-if="receiveState == 2"
          class="button1"
          :style="buttonStyle"
          color="linear-gradient(to right, #EC722E, #E93323)"
          disabled
        >
          今日已抢完
        </van-button>

        <van-button
          v-else
          class="button1"
          :style="buttonStyle"
          :loading="buttonLoading"
          color="linear-gradient(to right, #EC722E, #E93323)"
          @click="submitMission"
          :disabled="!mission1 || !mission2"
        >
          领取奖励
        </van-button>

        <van-button class="button2" :style="buttonStyle" @click="sendResume"
          >投递简历</van-button
        >
      </div>
    </div>

    <div class="img img5">
      <span
        class="send"
        :style="{
          fontSize: buttonStyle.fontSize,
        }"
        >{{ send }}</span
      >
    </div>

    <div class="img img6">
      <div class="outer">
        <div class="inner" :style="{ fontSize: normalFontSize }">
          <div class="head">
            <span class="name">排行榜</span>

            <div class="date" @click="showDate = true">
              <span>{{ shownDate }}</span>
              <van-icon name="play" />
            </div>
          </div>

          <div class="list">
            <van-row gutter="10" class="list-head">
              <van-col span="5" class="van-ellipsis">姓名</van-col>
              <van-col span="13" class="van-ellipsis">学校</van-col>
              <van-col span="6" class="van-ellipsis text-right"
                >投递数量</van-col
              >
            </van-row>

            <template v-if="topList.length">
              <van-row
                gutter="10"
                class="list-item"
                v-for="item in topList"
                :key="item.userId"
              >
                <van-col span="5" class="van-ellipsis">{{
                  item.userName || ''
                }}</van-col>
                <van-col span="14" class="van-ellipsis">{{
                  item.organizationName || ''
                }}</van-col>
                <van-col span="5" class="van-ellipsis text-right">{{
                  item.count || 0
                }}</van-col>
              </van-row>
            </template>

            <div v-else class="list-item text-center">
              立即投递，抢占排行榜！
            </div>
          </div>
        </div>

        <div class="top-loading" v-if="topLoading">
          <van-loading color="#A75824" :size="loadingSize" />
        </div>
      </div>
    </div>

    <div class="img img7"></div>

    <div class="img img8"></div>

    <div class="img img9"></div>

    <div class="bottom mobile-view-inner">
      <van-row align="center">
        <van-col :span="15">
          <span class="share-button"></span>
          <van-image
            :src="require('@/assets/images/activity10/share_button.png')"
            @click="share"
          />
        </van-col>
        <van-col :span="9">
          <van-image
            :src="require('@/assets/images/activity10/gift_button.png')"
            @click="jumpGift"
          />
        </van-col>
      </van-row>
    </div>

    <van-popup v-model:show="showSuccess" class="popup mobile-view-inner">
      <div
        class="mission-success"
        :src="require('@/assets/images/activity10/mission-success.png')"
      >
        <span class="code" :style="{ fontSize: codeFontSize }">{{ code }}</span>
        <span class="close" @click="close"></span>
        <span class="copy" @click="copy"></span>
        <span class="convert" @click="convert"></span>
        <span class="share" @click="share"></span>
      </div>
    </van-popup>

    <van-popup v-model:show="showFail" class="popup mobile-view-inner">
      <div
        class="mission-fail"
        :src="require('@/assets/images/activity10/mission-fail.png')"
      >
        <span class="close" @click="close"></span>
      </div>
    </van-popup>

    <van-popup v-model:show="showDate" position="bottom">
      <van-datetime-picker
        v-model="queryDate"
        type="month-day"
        title="选择月日"
        :formatter="formatter"
        :min-date="startDate"
        :max-date="endDate"
        @confirm="changeDate"
        @cancel="showDate = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { watch, ref, computed } from 'vue';
import {
  Icon,
  Image,
  Button,
  Notify,
  Col,
  Row,
  Popup,
  DatetimePicker,
  Loading,
  Dialog,
} from 'vant';
import { useWindowSize } from '@vant/use';
import {
  getActivityData,
  getActivityCoupon,
  getActivityRankingTop,
} from '@/api/api';
import hybird from '@/composables/hybird';
import wxShare from '@/composables/wx';
import download from '@/composables/download';
import filters from '@/composables/filters';

export default {
  name: 'Activity10',

  setup() {
    const { hybirdCall } = hybird();
    const { wxShareHandler } = wxShare();
    const { downloadHandler } = download();
    const { width, height } = useWindowSize();
    const { formattedDate } = filters();
    let ratio = ref(1);
    ratio.value = (width.value > 768 ? 768 : width.value) / 375;

    watch([width, height], () => {
      ratio.value = (width.value > 768 ? 768 : width.value) / 375;
    });

    const buttonStyle = computed(() => ({
      borderRadius: `${10 * ratio.value}px`,
      letterSpacing: `${3 * ratio.value}px`,
      fontSize: `${18 * ratio.value}px`,
    }));

    const normalFontSize = computed(() => `${14 * ratio.value}px`);

    const codeFontSize = computed(() => `${20 * ratio.value}px`);

    const loadingSize = computed(() => `${40 * ratio.value}px`);

    return {
      hybirdCall,
      wxShareHandler,
      downloadHandler,
      ratio,
      buttonStyle,
      normalFontSize,
      codeFontSize,
      loadingSize,
      formattedDate,
    };
  },

  data() {
    return {
      id: this.$route.query.id || '',
      token: this.$route.query.token || '',
      id1: '',
      id2: '',
      mission1: false,
      mission2: false,
      receiveState: 0,
      send: 0,
      topList: [],
      buttonLoading: false,
      showSuccess: false,
      code: '',
      index: 0,
      url1: '',
      url2: '',
      showFail: false,
      showDate: false,
      queryDate: '',
      topLoading: true,
      startDate: '',
      endDate: '',
    };
  },

  computed: {
    shownDate() {
      return this.formattedDate(this.queryDate);
    },
  },

  created() {
    if (!this.id) {
      return Notify('活动不存在或已过期');
    }

    this.wxShareHandler({
      title: '10.10 要你！要你！',
      desc: '易校招黄金十月就业季，超级就业福利来袭！',
    });

    if (this.token) {
      setTimeout(() => {
        this.hybirdCall(
          'activityJSMethod',
          JSON.stringify({
            type: 'title',
            value: '10.10 要你！要你！ - 易校招',
          })
        );
      }, 1000);
    }

    this.getInfo();
  },

  methods: {
    getInfo() {
      getActivityData({
        params: { activityId: this.id },
        headers: {
          token: this.token || '',
        },
      }).then((res) => {
        if (res) {
          if (Array.isArray(res.childActivity)) {
            if (res.childActivity[0]) {
              this.id1 = res.childActivity[0].id;
              this.receiveState =
                res.childActivity[0].receiveButtonState || '0';
              this.url1 = res.childActivity[0].redirectUrl || '';

              if (Array.isArray(res.childActivity[0].steps)) {
                this.mission1 = !!(res.childActivity[0].steps[0] || {}).value;
                this.mission2 = !!(res.childActivity[0].steps[1] || {}).value;
              }
            }

            if (
              res.childActivity[1] &&
              Array.isArray(res.childActivity[1].steps)
            ) {
              this.send = (res.childActivity[1].steps[0] || {}).value || 0;
              this.id2 = res.childActivity[1].id;
              this.url2 = res.childActivity[1].redirectUrl;
            }
          }

          if (Array.isArray(res.noHintCode) && res.noHintCode.length) {
            this.code = res.noHintCode[0] || '';
            this.url = this.url2;
            this.index = 2;
            this.showSuccess = true;
          }

          let date = new Date();

          if (res.activityBeginDate) {
            this.startDate = new Date(res.activityBeginDate * 1000);

            if (Date.now() < this.startDate) {
              Dialog({
                message: '本次活动还未开始，敬请期待~',
              });

              date = this.startDate;
            }
          }

          if (res.activityEndDate) {
            this.endDate = new Date(
              res.activityEndDate * 1000 + (24 * 60 * 60 - 1) * 1000
            );

            if (Date.now() > this.endDate) {
              Dialog({
                message: '本次活动已经结束，期待更多活动与你相遇~',
              });

              date = this.endDate;
            }
          }

          this.queryDate = date;
          this.getTopList();
        }
      });
    },

    getTopList() {
      this.topLoading = true;

      getActivityRankingTop({
        params: {
          activityId: this.id2,
          queryDate: this.shownDate,
        },
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.topList = res;
          }

          this.topLoading = false;
        })
        .catch(() => (this.topLoading = false));
    },

    submitMission() {
      if (!this.token) {
        return this.downloadHandler();
      }

      this.buttonLoading = true;

      getActivityCoupon({
        params: {
          activityId: this.id1,
        },
        headers: {
          token: this.token,
        },
      })
        .then((res) => {
          if (res.code == 1) {
            this.code = res.coupon;
            this.url = this.url1;
            this.index = 1;
            this.showSuccess = true;
          } else {
            this.showFail = true;
          }

          this.getInfo();
          this.buttonLoading = false;
        })
        .catch(() => (this.buttonLoading = false));
    },

    sendResume() {
      this.safeHybirdCall(
        JSON.stringify({
          type: 'sendResume',
        })
      );
    },

    share() {
      this.safeHybirdCall(
        JSON.stringify({
          type: 'share',
          id: this.id || '',
        })
      );

      setTimeout(() => {
        this.getInfo();
      }, 2000);
    },

    copy() {
      this.safeHybirdCall(
        JSON.stringify({
          type: 'copy',
          value: this.code || '',
        })
      );
    },

    close() {
      this.showSuccess = false;
      this.showFail = false;
    },

    convert() {
      this.safeHybirdCall(
        JSON.stringify({
          type: 'convert',
          index: this.index || 0,
          shopUrl: this.url || '',
        })
      );
    },

    jumpGift() {
      if (this.token) {
        this.$router.push({
          path: '/activity10_gift',
          query: {
            id: this.id,
            token: this.token,
          },
        });
      } else {
        this.downloadHandler();
      }
    },

    safeHybirdCall(param) {
      if (this.token) {
        this.hybirdCall('activityJSMethod', param);
      } else {
        this.downloadHandler();
      }
    },

    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      }
      if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },

    changeDate(value) {
      console.log(value);
      this.queryDate = value;
      this.showDate = false;
      this.getTopList();
    },
  },

  components: {
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Loading.name]: Loading,
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.img1 {
  padding-top: 113%;
  background-image: url('~@/assets/images/activity10/img1.png');
}

.img2 {
  padding-top: 41.3%;
  background-image: url('~@/assets/images/activity10/img2.png');
}

.img3 {
  padding-top: 80%;
  background-image: url('~@/assets/images/activity10/img3.png');
}

.img4 {
  padding-top: 48.5%;
  background-image: url('~@/assets/images/activity10/img4.png');

  .mission1 {
    position: absolute;
    top: 10.9%;
    right: 10.1%;
    width: 18.4%;
  }

  .mission2 {
    position: absolute;
    top: 32.4%;
    right: 10.1%;
    width: 18.4%;
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 3.3%;
    right: 4.1%;
    height: 35.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button1 {
      width: 76%;
      height: 100%;
    }

    .button2 {
      width: 18.9%;
      height: 100%;
      padding: 0 2%;
      border-color: var(--van-button-default-background-color);
      color: #ea3f25;
    }
  }
}

.img5 {
  padding-top: 95.2%;
  background-image: url('~@/assets/images/activity10/img5.png');

  .send {
    position: absolute;
    bottom: 10.2%;
    right: 9.3%;
    color: #a75725;
    line-height: 1;
  }
}

.img6 {
  height: auto;
  background-image: url('~@/assets/images/activity10/bg.png');
  background-repeat: repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .outer {
    margin: 0 3.5%;
    background: #fcc88f;
    box-sizing: border-box;
    border-radius: 3%;
    overflow: hidden;
    position: relative;

    .inner {
      margin: 2.5%;
      padding: 4%;
      background: #fff5e1;
      border-radius: 3%;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
          padding: 0.3em 0.9em;
          background: #a75725;
          color: #fff;
          border-radius: 0.35em;
          letter-spacing: 0.15em;
        }

        .date {
          padding: 0.4em 0.9em;
          background: #fff;
          border-radius: 1em;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
          color: #e78e35;
        }

        .van-icon {
          margin-left: 0.3em;
          font-size: 0.8em;
          transform: rotate(90deg) translate(-0.15em);
        }
      }

      .list {
        margin-top: 1.5em;
        color: #a75725;

        .list-head {
          padding-bottom: 1em;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0.5px;
            background-image: linear-gradient(
              to right,
              #a75725 0%,
              #a75725 50%,
              transparent 50%
            );
            background-size: 0.8em 0.1em;
            background-repeat: repeat-x;
          }
        }

        .list-item {
          margin-top: 1em;
        }
      }
    }

    .top-loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.img7 {
  padding-top: 232%;
  background-image: url('~@/assets/images/activity10/img7.png');
}

.img8 {
  padding-top: 56%;
  background-image: url('~@/assets/images/activity10/img8.png');
}

.img9 {
  padding-top: 20%;
  background-image: url('~@/assets/images/activity10/bg.png');
}

.bottom {
  position: fixed;
  left: 50%;
  bottom: 0.2em;
  transform: translateX(-50%);
  width: 95%;
}

:deep(.popup) {
  width: 85%;
}

.mission-success {
  position: relative;
  padding-top: 81%;
  background-image: url('~@/assets/images/activity10/mission-success.png');
  background-size: cover;

  .code {
    position: absolute;
    top: 39%;
    left: 29%;
    color: #a75824;
  }

  .close {
    position: absolute;
    right: 1%;
    top: 2%;
    width: 6%;
    height: 8%;
  }

  .copy {
    position: absolute;
    left: 20%;
    top: 29.6%;
    width: 69%;
    height: 27%;
  }

  .convert {
    position: absolute;
    left: 14%;
    bottom: 7%;
    width: 35%;
    height: 15%;
  }

  .share {
    position: absolute;
    right: 14%;
    bottom: 7%;
    width: 35%;
    height: 15%;
  }
}

.mission-fail {
  position: relative;
  padding-top: 81%;
  background-image: url('~@/assets/images/activity10/mission-fail.png');
  background-size: cover;

  .close {
    position: absolute;
    right: 1.5%;
    top: 2%;
    width: 6%;
    height: 8%;
  }
}
</style>
